import { QSAppMetadataFamily, QlikUser as QlikUserAtom } from "@trinity-incyte/recoil";
import { KPIBox, PDRPMessage, QSMashupObject, Redacted } from "@trinity-incyte/ui";
import React, { useContext } from "react";
import { useRecoilValue } from "recoil";
import Config from "../../Config";
import { useMashup } from '@trinity-incyte/hooks';
import ImgDigitalPull from '../../../assets/Images/digital_pull.png';
import ImgDigitalPush from '../../../assets/Images/digital_push.png';
import ImgFaceToFace from '../../../assets/Images/face_to_face.png';
import QSTableTiles from "../Other/QSTableTiles";
import ImgPeerInteractionIcon from '../../../assets/Images/peer_to_peer.png';
import { HCP360Context } from "@trinity-incyte/context";
import { StarFilled } from "@ant-design/icons";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { BasicTableColumnRenderer } from "libs/ui/src/lib/Qlik/qscustom-table/basic-table/basic-table";
declare const Mosaic: MosaicGlobal;

const config = Config.Qlik.Prof360;
const { appId } = config.ids;
let dateValue = "";


export const HCP360PemazyreOverview = {
    'PEMAZYRE Bottles Box': {
        component: (props) => {
            const QlikUser = useRecoilValue(QlikUserAtom);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const hcp360Context = useContext(HCP360Context);
            const pdrpOptout = hcp360Context.profData?.pdrpOptout;
            if (pdrpOptout == null) {
                //The state of the pdrp hasn't been determined yet, so don't render anything
                return (<></>);
            }
            if (pdrpOptout === 'Y') {
                //The prof has opted out of having their data visible, so don't show it
                return (<PDRPMessage customClass="kpibox kpi-threesixty" />)
            }

            const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    {suppressSalesData ? <Redacted titleProp={"Sales Volume"} /> :
                        <>                    
                            <div className="header-threesixty">{"Sales Volume"}</div>
                            <QSMashupObject
                                appId={appId}
                                customClass="basicTableFour"
                                mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre Bottles')?.id}
                                isBasicTable
                                compactMargin
                                compact
                                {...props}
                            />
                        </>
                    }
                </KPIBox>
            )
        }
    },
    'PEMAZYRE Treatment LTD': {
        component: (props) => {
            const QlikUser = useRecoilValue(QlikUserAtom);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const hcp360Context = useContext(HCP360Context);
            const pdrpOptout = hcp360Context.profData?.pdrpOptout;

            if (pdrpOptout == null) {
                //The state of the pdrp hasn't been determined yet, so don't render anything
                return (<></>);
            }
            if (pdrpOptout === 'Y') {
                //The prof has opted out of having their data visible, so don't show it
                return (<PDRPMessage customClass="kpibox kpi-threesixty" />)
            }

            const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;
  
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    {suppressSalesData ? <Redacted titleProp={"Treatment LTD"} /> :
                        <>
                            <div className="header-threesixty">{"Treatment LTD"}</div>
                            <div style={{textAlign:'center', width:'100%'}}>
                                <QSMashupObject isText compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre First Rx')?.id} />
                                <hr style={{width:'50%', border:'#eee 1px solid', margin:'6px auto'}}/>
                                <QSMashupObject isText compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre Last Rx')?.id} />
                            </div>
                        </>
                    }
                </KPIBox>
            )}
    },
    'PEMAZYRE Multi Channel Engagement': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/MCE/${props.profId}`}>
                <div className="header-threesixty-linked">{"Multi Channel Engagement"}</div>
                <div style={{display:'flex', width:'100%', flexWrap:'wrap', margin:'1px'}}>
                    <div style={{width:'50%', padding:'0'}}>
                    <QSMashupObject isText progressIconLeft={ImgFaceToFace} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Face to Face AFF KPI')?.id} />
                    </div>
                    <div style={{width:'50%', padding:'0'}}>
                    <QSMashupObject isText progressIconLeft={ImgPeerInteractionIcon} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Peer Interaction KPI')?.id} />          
                    </div>
                    <div style={{width:'50%', padding:'0'}}>
                    <QSMashupObject isText progressIconLeft={ImgDigitalPush} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Push KPI')?.id} />          
                    </div>
                    <div style={{width:'50%', padding:'0'}}>
                    <QSMashupObject isText progressIconLeft={ImgDigitalPull} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Pull KPI')?.id} />          
                    </div>
                </div>
                </KPIBox>
        )}
    },
    'PEMAZYRE Connections': {
        component: (props) => {        
            const mashupData = useMashup(appId, 'Mosaic_HCP 360 - Overview_HCP Connections KPI');
            const numConnections = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/Connections/${props.profId}`}>
                    <div className="header-threesixty text-center">{"Connections"}</div>
                    <div className="text-center qsKPIValue">{numConnections}</div>
                </KPIBox>
            )
        }
    },
    'PEMAZYRE Affiliations': {
        component: (props) => {
            const mashupData = useMashup(appId, 'Mosaic_HCP 360 - Overview_HCP Affiliations KPI');
            const numAffiliations = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/Affiliations/${props.profId}`}>
                    <div className="header-threesixty text-center">{"Affiliations"}</div>
                    <div className="text-center qsKPIValue">{numAffiliations}</div>
                </KPIBox>
            )
        }
    },
    'PEMAZYRE HCP Activity': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}`} customClass="kpibox kpi-threesixty">
                <div className="header-threesixty-linked" style={{margin:'4px 0px 8px 0px'}}>{"HCP Activity"}</div>
                <QSMashupObject
                appId={appId}
                objectKey='UpVLTw'
                customClass="basicTable"
                mashupId={ids.get('Mosaic_HCP 360 - Overview_HCP Most Recent Activity')?.id}
                isBasicTable
                compactMargin
                compact
                {...props}
            />
            </KPIBox>
        )}
    },
    'PEMAZYRE Alerts': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Alerts/${props.profId}`}>
                <div className="header-threesixty-linked">{"Alerts"}</div>
                <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%'}}>
                    <QSTableTiles appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre Alert Tiles')?.id} labelColumnId={0} valueColumnId={1} />
                </div>
            </KPIBox>
            )
        }
    },
    'PEMAZYRE Barriers': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids, FieldsTable: fields } = metadata;
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Barriers/${props.profId}`}>
                <div className="header-threesixty-linked" style={{margin:'4px 0px 8px 0px'}}>
                    {"Barriers"}
                </div>
                <QSMashupObject
                    compact
                    compactMargin
                    customClass="basicTable"
                    isBasicTable
                    appId={appId}
                    mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre Barrier Tiles')?.id} 
                    columnRenderers={new Map<string, BasicTableColumnRenderer>([
                        ["Indication", { cellRenderer: (cellInfo, columns, data) => {
                            return (
                                <td key={`dataCell_Indication`} style={{ }}>
                                    {data.RecentFlag === "Y" ? <StarFilled style={{ color: 'gold', marginTop: '1px', marginRight: '0.5em', stroke: 'red', strokeWidth: '40' }} /> : ""}
                                    {cellInfo}
                                </td>
                            )
                        } }],
                        ["RecentFlag", { headerRenderer: (cellInfo, columns, index) => { }, cellRenderer: (cellInfo, columns, data) => {} }]
                    ])}
                />
            </KPIBox>
            )
        }
    },
    'PEMAZYRE CCA Landscape': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty">
            <div className="header-threesixty">{"CCA Landscape"}</div>
            <QSMashupObject
                    appId={appId}
                    objectKey='Mosaic_HCP 360 - Overview_CCA Landscape'
                    customClass="basicTable"
                    mashupId={ids.get('Mosaic_HCP 360 - Overview_CCA Landscape')?.id}
                    isBasicTable
                    compactMargin
                    compact
                    {...props}
                />
            </KPIBox>
        )}
    },
    'PEMAZYRE Patients': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const hcp360Context = useContext(HCP360Context);
            const pdrpOptout = hcp360Context.profData?.pdrpOptout;

            if (pdrpOptout == null) {
                //The state of the pdrp hasn't been determined yet, so don't render anything
                return (<></>);
            }
            if (pdrpOptout === 'Y') {
                //The prof has opted out of having their data visible, so don't show it
                return (<PDRPMessage customClass="kpibox kpi-threesixty" />)
            }

            return (
            <KPIBox customClass="kpibox kpi-threesixty">
            <div className="header-threesixty">{"Patients"}</div>
            <QSMashupObject
                    appId={appId}
                    objectKey='Mosaic_HCP 360 - Overview_Pemazyre Patients'
                    customClass="basicTable"
                    mashupId={ids.get('Mosaic_HCP 360 - Overview_Pemazyre Patients')?.id}
                    isBasicTable
                    compactMargin
                    compact
                    {...props}
                />
            </KPIBox>
        )}
    },
}

export default HCP360PemazyreOverview;