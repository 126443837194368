import React, { ReactNode } from 'react';
import { Typography, Modal } from 'antd';
import { SerializableParam, atom, atomFamily } from 'recoil';


export const LandingAlertsMeta = atom({
    key: 'LandingAlertsMeta',
    default: {
        companies: {},
        brands: {},
        types: {},
    },
});

export const dataDateRetail = atom({
    key: 'dataDateRetail',
    default: null,
});

export const bioState = atom({
    key: 'bioState',
    default: null,
});

export const rowFiltersState = atom({
    key: 'rowFiltersState',
    default: {
        companies: { All: 0 },
        teams: { All: 0 },
        types: { All: 0 },
        totalCount: { All: 0 },
    },
});

export const activeBrand = atom({
    key: 'activeBrand',
    default: null,
});

export type dismissedAlert = {
    AlertParseDbId:string,
    AlertKey:string,
    AlertType:string,
}

export const dismissedAlerts = atom({
    key: 'dismissedAlerts',
    default: [] as Array<dismissedAlert>
});

export type dismissedAlertReason = {
    id:string,
    description:string
};

export const dismissedAlertsReasons = atom({
    key:'dismissedAlertReasons',
    default: [] as Array<dismissedAlertReason>,
});

export type IncyteTeam = 'HO1' | 'HO2' | 'HO3' | 'OCNE' | 'BASE' | 'OVERLAY';

export const activeTeam = atom({
    key: 'activeTeam',
    default: null as IncyteTeam,
});

export const primaryTeam = atom({
    key: 'primaryTeam',
    default: null as IncyteTeam,
});

export const defaultSelection = atom({
    key: 'defaultSelection',
    default: {},
});

export const isMasqueradeActive = atom({
    key: 'isMasqueradeActive',
    default: false,
});

export const currentMasqueradeSelection = atom({
    key: 'currentMasqueradeSelection',
    default: "",
});


export const regionSelectionCount = atom({
    key: 'regionSelectionCount',
    default: 0,
});

/* UI */
export const lastUpdateTimeState = atom({
    key: 'lastUpdateTimeState',
    default: null,
});

export const activePageState = atom({
    key: 'activePageState',
    default: 'FS_MAIN',
});

export const authenticatedUserState = atom({
    key: 'authenticatedUserState',
    default: false,
});

export const filtersIsOpenState = atom({
    key: 'filtersIsOpenState',
    default: false,
});

export const userIdState = atom({
    key: 'userIdState',
    default: false,
});

export const masqueradeUserId = atom<any>({
    key: 'masqueradeUserId',
    default: false,
});

export const showFiltersState = atom({
    key: 'showFiltersState',
    default: false,
});

export const customFiltersState = atom({
    key: 'customFiltersState',
    default: null as ReactNode,
});

export const showDetailsState = atom({
    key: 'showDetailsState',
    default: false,
});

export const showStaffCardState = atom({
    key: 'showStaffCardState',
    default: false,
});

export const showTestState = atom({
    key: 'showTestState',
    default: false,
});

export const hasAcceptedState = atom({
    key: 'hasAcceptedState',
    default: false, // Set to false to enable Legal Disclaimer modal
});

export const hasOrgPurchasedState = atom({
    key: 'hasOrgPurchasedState',
    default: false,
});

export const detailsTabIndexState = atom({
    key: 'detailsTabIndexState',
    default: 0,
});

export const hrefGoogleMapState = atom({
    key: 'hrefGoogleMapState',
    default: '',
});

export const hrefWebIconState = atom({
    key: 'hrefWebIconState',
    default: '',
});

export const hrefSalesforceState = atom({
    key: 'hrefSalesforceState',
    default: '',
});

export const hrefMosaicCommState = atom({
    key: 'hrefMosaicCommState',
    default: '',
});

export const profImgUrlState = atom({
    key: 'profImgUrlState',
    default: '',
});

export const sideNavIsExpandedState = atom({
    key: 'sideNavIsExpandedState',
    default: true,
});

export const filtersTransitionTimingState = atom({
    key: 'filtersTransitionTimingState',
    default: 330, // in milliseconds
});

export const filtersTransitionEasingState = atom({
    key: 'filtersTransitionEasingState',
    default: 'linear',
});

export const screenOrientationState = atom({
    key: 'screenOrientationState',
    default: {
        isPortrait: window.matchMedia('(orientation: portrait)').matches,
    },
});

//type MODAL_SIZE = 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';

export const modalState = atom({
    key: 'modalState',
    default: {
        width: "50%",
        visible: false,
        mask: true,
        maskClosable: true,
        closable: false,
        keyboard: true,
        footer: null,
        content: (
            <>
                <Modal title="Modal" footer={null}>
                    <p>Lorem ipsum</p>
                </Modal>            
            </>
        ),
    },
});

type WORKSPACE_WIDTH = 20 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
export const workspaceState = atom({
    key: 'workspaceState',
    default: {
        width: 40 as WORKSPACE_WIDTH,
        title: (<Typography.Title level={3}>Prof Workspace</Typography.Title>),
        isOpen: false,
        content: (
            <>
                <h4>Workspace</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </>
        ),
    },
});

type WORKSPACE_CHILD_WIDTH = 20 | 40 | 50 | 60 | 70 | 80;
export const workspaceChildState = atom({
    key: 'workspaceChildState',
    default: {
        width: 40 as WORKSPACE_CHILD_WIDTH,
        title: (<Typography.Title level={3}>Form</Typography.Title>),
        isOpen: false,
        content: (
            <>
                <h4>Workspace Child</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </>
        ),
    },
});

interface SubPageProps {
    isOpen: boolean;
    mashupId: string;
    appId: string;
    elementId: string;
    tableProps: any;
    dynamic?: boolean;
    onUnMount: ()=>void;
}

export const subPageState = atom({
    key: 'subPageState',
    default: {
        isOpen: false,
        mashupId: '',
        elementId: '',
        appId: '',
        onUnMount: null
    } as SubPageProps,
});

interface SliderValue {
    isOpen: boolean;
    content: any;
    key: any;
}

export const bottomSliderState = atom({
    key: 'bottomSliderState',
    default: {
        isOpen: false,
        content: <></>,
    } as SliderValue,
});

export const leftSliderState = atom({
    key: 'leftSliderState',
    default: {
        isOpen: false,
        content: <></>,
        key: false,
    } as SliderValue,
});

export const myGeoState = atom({
    key: 'myGeoState',
    default: true,
});

export const mosaicSearchResultsState = atom({
    key: 'mosaicSearchResultsState',
    default: new Map(),
});

export const connectionExistState = atom({
    key: 'connectionExisState',
    default: false,
});

export const connectedProfileRefState = atom({
    key: 'connectedProfileRefState',
    default: undefined,
});

/* End UI */

type APPSTATE_STATES =
    | false
    | 'LOADING'
    | 'LOADING QLIK'
    | 'LOADING APP'
    | 'MAINTENANCE'
    | 'PROCESSING'
    | 'ALL GOOD'
    | 'ERROR';
type APPSTATE_ERRORS =
    | false
    | 'MAINTENANCE'
    | 'NO REQUIRE'
    | 'NO QLIK'
    | 'NO APP'
    | 'NO PERMISSION'
    | 'EXPIRED SESSION'
    | 'OTHER';
interface AppStateProps {
    state: APPSTATE_STATES;
    error: APPSTATE_ERRORS;
    content?: null | string;
}
export const AppState = atom({
    key: 'AppState',
    default: {
        state: 'LOADING' as APPSTATE_STATES,
        error: false as APPSTATE_ERRORS,
        content: null,
    } as AppStateProps,
});

type QLIKUSER_SESSIONS = boolean;
type QLIKUSER_EMPTYPES = false | any;
type QLIKUSER_ACCESSTYPES = false | any;
type QLIKUSER_TEAM = false | any;
type QLIKUSER_AUTHENTICATED_USER = false | any;
interface QlikUserProps {
    appName: any;
    session?: QLIKUSER_SESSIONS;
    empType: QLIKUSER_EMPTYPES;
    accessType: QLIKUSER_ACCESSTYPES;
    isTrainer: boolean,
    features?: any;
    team: QLIKUSER_TEAM;
    authenticatedUser: QLIKUSER_AUTHENTICATED_USER;
    showTeamSelector: false | string[];
    showLeaderboard: false | 'REGION' | 'TERRITORY';
    showSidebarFilters: boolean;
    showMyGeoControl: boolean;
    showMasquerade: boolean;
    showAvgCallsPerDay: boolean;
    showTerritoryComparison : boolean;
    showTRxRegionFilter: boolean;
    showTRxTerritoryFilter: boolean;
    dataDate: any;
    pageVisibility: {
        home: boolean
        fieldSales: boolean
        events: boolean
        hcp360: boolean
        hco360: boolean
        listBuilder: boolean
        trainer: boolean
        chatBot: boolean
    }
}
export const QlikUser = atom({
    key: 'QlikUser',
    default: {
        appName: false,
        session: false,
        empType: false,
        accessType: false,
        isTrainer: false,
        features: {},
        team: false,
        authenticatedUser: false,
        showTeamSelector: false,
        showLeaderboard: false,
        showSidebarFilters: false,
        showMyGeoControl: true,
        showMasquerade: false,
        showAvgCallsPerDay: false,
        showTerritoryComparison: false,
        showTRxRegionFilter: false,
        showTRxTerritoryFilter: false,
        dataDate: {},
        pageVisibility: {
            home: true,
            fieldSales: true,
            events: true,
            hcp360: true,
            hco360: true,
            listBuilder: true,
            trainer: true,
            chatBot: true
        }
    } as QlikUserProps,
});

export const QlikAppsMetadata = atom({
    key: 'QlikAppsMetadata',
    default: {},
});

export const QlikLastModified = atom({
    key: 'QlikLastModified',
    default: Date.now(),
});

export const QlikProfData = atom({
    key: 'QlikProfData',
    default: false,
});

type USERTERR = false | any;
interface UserTerritoryProps {
    ho1?: USERTERR;
    ho2: USERTERR;
    ho3: USERTERR;
    ocne: USERTERR;
    base: USERTERR;
    overlay: USERTERR;
}
export const UserTerritory = atom({
    key: 'UserTerritory',
    default: {
        ho1: false,
        ho2: false,
        ho3: false,
        ocne: false,
        base: false,
        overlay: false,
    } as UserTerritoryProps,
});

/* End Qlik */

export const GridOptions = atom({
    key: 'GridOptions',
    default: null,
});

export const ListBuilderState = atom({
    key: 'ListBuilderState',
    default: null,
});

type QSAppMetadata = {
    FieldsTable: any,
    IDsTable: any,
    DimensionsList: any,
    MeasuresList: any,
    FieldsList: any,
    AlertsDismissals: any
}
export const QSAppMetadataFamily = atomFamily<QSAppMetadata, SerializableParam>({
    key: 'QSAppMetadata',
    default: null,
});

export const ListBuilderFields = atom({
    key: 'ListBuilderFields',
    default: []
});

export const ListBuilderFilters = atom({
    key: 'ListBuilderFilters',
    default: []
});

export const ListBuilderSegments = atom({
    key: 'ListBuilderSegments',
    default: []
});

export const HCP360Dimensions = atom({
    key: 'HCP360Dimensions',
    default: {
		qDimensionList: { qItems: [] },
	}
})