import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import React, { useContext, useEffect } from 'react'
import { ConfigContext, HCO360Context } from '@trinity-incyte/context'
import { KPIBox, QSMashupObject, Redacted, TabGroup } from '@trinity-incyte/ui'
import { useRecoilValue } from 'recoil'
import { QSAppMetadataFamily, QlikUser as QlikUserAtom } from '@trinity-incyte/recoil'
declare const Mosaic: MosaicGlobal;

type HCO360HCPsParams = {
    brand: string
    groupId: string
}

const targetsColOverrides = {
    colOverrides : [
        {
            columns: [0],
            hStyles: { width: "18rem"},
        },
        {
            columns: [1],
            hStyles: { width: "12rem" },
        },
        {
            columns: [2],
            hStyles: { width: "10rem" },
        },
        {
            columns: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
            hStyles: { width: "7rem" },
        },
        ]
}

const JakafiHCPTargets = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Jakafi_Affiliated HCPs"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        showExports={(true)}
                        tableProps={targetsColOverrides}
                        isTable
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

const JakafiKeyIndividuals = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Overview_Jakafi Key Individuals"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        showExports={(true)}
                        isTable
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

const PemazyreHCPTargets = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Pemazyre_Affiliated HCPs"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        tableProps={targetsColOverrides}
                        isTable
                        showExports={(true)}
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

const PemazyreKeyIndividuals = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Overview_Pemazyre Key Individuals"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        isTable
                        showExports={(true)}
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

const MonjuviHCPTargets = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Monjuvi_Affiliated HCPs"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        tableProps={targetsColOverrides}
                        isTable
                        showExports={(true)}
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

const MonjuviKeyIndividuals = () => {
    const QlikUser = useRecoilValue(QlikUserAtom);
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata
    const objectId = "Mosaic_HCO 360_Overview_Monjuvi Key Individuals"
    const suppressSalesData = QlikUser?.features?.APP?.suppressSalesData;

    return (
        <KPIBox customClass="kpibox" style={{ flex: 1 }}>
            {suppressSalesData ? <Redacted /> :
                <>
                    <QSMashupObject
                        hideTitle
                        appId={appId}
                        objectKey={objectId}
                        mashupId={ids.get(objectId)?.id}
                        isTable
                        showExports={(true)}
                        compact
                        compactMargin
                    />
                </>
            }
        </KPIBox>
    )
}

export const HCO360HCPs = ({ brand, groupId }: HCO360HCPsParams) => {
    const hco360Context = useContext(HCO360Context)
    const Config = useContext(ConfigContext);

    
    const clearFilters = () => {
        const hcp360App = Mosaic.Qlik.app[Config.Qlik.Prof360.ids.appId];
        if ( hcp360App ) {
            hcp360App.field( '%GP_ID' ).clearOther( true );
        }
    };

    useEffect(() => {        
        hco360Context.setActiveView('HCPs')
        hco360Context.setBrand(brand)
		hco360Context.applyGroupId(groupId)
		hco360Context.setShowGroup(true)
	}, [])

    let HCPTargetComponent
    let KeyIndividualsComponent
    switch (brand) {
        case "Jakafi":
            HCPTargetComponent = JakafiHCPTargets
            KeyIndividualsComponent = JakafiKeyIndividuals
            break
            
        case "Pemazyre":
            HCPTargetComponent = PemazyreHCPTargets
            KeyIndividualsComponent = PemazyreKeyIndividuals
            break
            
        case "Monjuvi":
            HCPTargetComponent = MonjuviHCPTargets
            KeyIndividualsComponent = MonjuviKeyIndividuals
            break
        default:
            break;
    }

    return (
        <TabGroup tabs={[{name: "Affiliated HCPs", comp: HCPTargetComponent}, {name: "Key Individuals", comp: KeyIndividualsComponent}]}  onTabChange={clearFilters} />
    )
}
